import React from "react";
import styled from "@emotion/styled"


const Markdown = styled("article")`
margin-top: ${props => props.theme.spacing(1)};
color: ${props => props.theme.color.text.high};

p {
    padding: 0.75rem 0;
    line-height: 1.75rem;
}

ul {
  padding-left: 1rem;
  list-style-type: square;
}

li {
    padding-bottom: 0.25rem;
    line-height: 1.75rem;
}

pre {
    background-color: rgba(0, 0, 0, 0.1);
    border: solid 2px ${props => props.theme.color.text.high};
    border-radius: 2px;
    padding: 0.5rem;
    color: ${props => props.theme.color.text.high};
}

${ props => [1, 2, 3].map(n => `h${n}{ ${props.theme.typography[`headline${n + 3}`]} }`)}

blockquote{
  padding: 0 0.5rem;
  margin: 0.5rem 0;
  border - left: solid 8px ${ props => props.theme.color.primaryLight};
  border - radius: 2px;
  background - color: ${ props => props.theme.color.text.light};
  color: ${ props => props.theme.color.text.high};
}
`

export default ({ children }) => <Markdown dangerouslySetInnerHTML={{ __html: children }} />