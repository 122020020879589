import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Title from "../components/title"
import MarkdownDoc from "./components/markdownDoc"
import styled from "@emotion/styled"

const MarkdownFrame = styled.div`
  ${props => props.theme.media.devices.desktop} {
    max-width: ${props => props.theme.media.breakpoints.md};
  }

  text-align: justify;
`

export default function({ data }) {
  const { md } = data

  return (
    <Layout>
      <MarkdownFrame>
        <Title>{md.frontmatter.title}</Title>
        <MarkdownDoc>{md.html}</MarkdownDoc>
      </MarkdownFrame>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    md: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
      html
    }
  }
`
